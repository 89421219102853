//
// Header
//

.header {
	padding-top: 1rem;
	padding-bottom: 1rem;
	border-top: 5px solid $primary;
	background-color: $primary;
}

.header-logo {
	display: flex;
	justify-content: center;
}

// Button
.header-button-container {
	position: fixed;
	top: 3rem;
	right: 0;
	z-index: 3;
	display: flex;
	flex-direction: column;

	button,
	.btn {
		margin-bottom: 1rem;
		border: none;
		padding: 0;
		width: 80px;
		height: 80px;
		background-color: $primary;
		cursor: pointer;
	}

	.btn-menu {
		position: relative;

		> span {
			display: block;
			margin-top: -12px;
		}

		&__icon {
			display: block;
			margin-left: auto;
			margin-right: auto;
			margin-top: 9px;
			width: 25px;
			height: 3px;
			background-color: $white;

			&:before {
				content: '';
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 25px;
				height: 3px;
				background-color: $white;
				transform: translateY(-6px);
			}

			&:after {
				content: '';
				display: block;
				margin-left: auto;
				margin-right: auto;
				width: 25px;
				height: 3px;
				background-color: $white;
				transform: translateY(3px);
			}
		}
	}

	.btn-download {
		display: flex;
		justify-content: center;
		align-items: center;
	}
}

#header-menu {
	position: fixed;
	left: 0;
	top: 0;
	z-index: 3;
	max-width: 200px;
	width: 100%;
}

// Nav
.header-nav {
	background-color: $primary;

	.nav-link {
		font-size: $font-size-base * 1.5;
		font-weight: bold;
		color: $white;

		&:hover,
		&:focus {
			text-decoration: underline;
		}
	}
}