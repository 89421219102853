.footer {
	margin-top: 8rem;
	padding-top: 1rem;
	padding-bottom: 1rem;
	background-color: $primary;
}

.footer-inner {
	position: relative;
}

.footer-nav {
	margin-bottom: 1rem;

	&-item > a {
		color: $white;

		&:hover {
			text-decoration: underline;
		}
	}
}

.footer-page-top {
	position: absolute;
	right: 0;
	bottom: 100%;
	margin-bottom: 3rem;
}

.btn-page-top {
	position: relative;
	width: 75px;
	height: 75px;

	&:before {
		content: '';
		display: block;
		margin: rem(4) auto;
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: $white;
	}

	&:after {
		content: '';
		position: absolute;
		left: 50%;
		top: 50%;
		display: block;
		width: 12px;
		height: 12px;
		margin-left: -6px;
		margin-top: -14px;
		border-top: 3px solid $primary;
		border-left: 3px solid $primary;
		border-radius: 2px;
	    transform: rotate(45deg);
	}
}