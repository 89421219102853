﻿//
// Home
//

body.home {

	.header {
		padding: 0;
		background-color: transparent;
	}

	.header-logo {
		position: absolute;
		z-index: 2;
		left: 0;
		top: 25%;
		display: flex;
		align-items: center;
		padding: 0 1rem;
		width: 100%;
	}

	.hero {
		position: relative;
		margin-bottom: 3rem;
		height: 1080px;
		overflow: hidden;
		background-image: url(#{$path-img}/home/hero.jpg);
		background-position: center center;

		&:before {
			content: "";
			position: absolute;
			left: 0;
			bottom: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 220px 100vw;
			border-color: transparent transparent $white transparent;
		}
	}

	.hero-donation {
		position: absolute;
		left: 0;
		top: 60%;
		width: 100%;
	}

	.btn-donation {
		position: relative;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-left: auto;
		margin-right: auto;
		width: 200px;
		height: 200px;
		border-radius: 50%;
		font-size: rem(18);

		&:before {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 1.5rem;
			margin-left: -15px;
			margin-top: -15px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: $white;
		}

		&:after {
			content: '';
			position: absolute;
			left: 50%;
			bottom: 2rem;
			display: block;
			width: 12px;
			height: 12px;
			margin-left: -9px;
			margin-top: -6px;
			border-top: 3px solid $primary;
			border-left: 3px solid $primary;
			border-radius: 2px;
		    transform: rotate(135deg);
		}
	}

	h2 {
		margin-bottom: 3rem;

		span {
			display: block;
			font-size: rem(21);
			color: $primary;
		}
	}

	.large-heading {
		margin-bottom: 2rem;
		font-size: $font-size-base * 4;
		line-height: .8;
	}

	.large-paragraph {
		font-size: $font-size-base * 1.5;
		font-weight: bold;
	}

	.bracket-box {
		position: relative;
		padding: 1rem;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			border-left: 2px solid $black;
			border-top: 2px solid $black;
			width: 70px;
			height: 70px;
		}

		&:after {
			content: '';
			position: absolute;
			right: 0;
			bottom: 0;
			border-right: 2px solid $black;
			border-bottom: 2px solid $black;
			width: 70px;
			height: 70px;
		}

		p:last-child {
			margin: 0;
		}
	}

	.btn-more {
		position: relative;
		padding-left: 3.5rem;
		padding-right: 2rem;
		padding-top: 1rem;
		padding-bottom: 1rem;
		font-size: rem(18);

		&:before {
			content: '';
			position: absolute;
			left: 1rem;
			top: 50%;
			margin-top: -15px;
			width: 30px;
			height: 30px;
			border-radius: 50%;
			background-color: $white;
		}

		&:after {
			content: '';
			position: absolute;
			left: 2rem;
			top: 50%;
			display: block;
			width: 12px;
			height: 12px;
			margin-left: -9px;
			margin-top: -6px;
			border-top: 3px solid $primary;
			border-left: 3px solid $primary;
			border-radius: 2px;
		    transform: rotate(135deg);
		}
	}

	.hero-support {
		position: absolute;
		right: 10%;
		bottom: 0;
		padding-left: 3rem;
		padding-right: 8rem;
		padding-top: 4rem;
		max-width: 620px;
		width: 100%;
		height: 230px;
		background-image: url(#{$path-img}/home/hero-support-background.png);
		background-size: contain;
		background-repeat: no-repeat;

		@include media-breakpoint-down(sm) {
			right: 0;
			border: 5px solid $primary;
			padding: 1rem;
			max-width: none;
			height: auto;
			background-image: none;
			background-color: $white;
		}

		h2 {
			margin: 0;
			font-size: $font-size-base;
		}

		p {
			margin: 0;
			font-size: $font-size-base * 1.5;
			font-weight: bold;
		}
	}

	.about {
		padding-top: 4rem;
		padding-bottom: 4rem;

		.container {
			position: relative;

			&:before {
				content: '';
				position: absolute;
				right: 0;
				bottom: 0;
				z-index: 2;
				margin-bottom: -395px;
				width: 370px;
				height: 790px;
				background-image: url(#{$path-img}/home/about-image.png);
				background-size: contain;
				background-repeat: no-repeat;

				@include media-breakpoint-down(sm) {
					display: none;
				}
			}
		}
	}

	.visual-1,
	.visual-3 {
		position: relative;
		height: 680px;
		background-image: url(#{$path-img}/home/visual-1.jpg);
		background-position: center center;
		background-repeat: no-repeat;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 100vw 220px 0;
			border-color: transparent $white transparent transparent;

			@include media-breakpoint-down(lg) {
				border-width: 0 100vw 110px 0;
			}
		}

		&:after {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 0 200px 100vw;
			border-color: transparent transparent $white transparent;

			@include media-breakpoint-down(lg) {
				border-width: 0 0 110px 100vw;
			}
		}
	}

	.message-item {
		margin-bottom: 4rem;
	}

	.message-author {
		@include media-breakpoint-down(sm) {
			margin-bottom: 2rem;
		}
	}

	.message-section {
		position: relative;
		padding-top: 1rem;
		padding-bottom: 1rem;
		overflow: hidden;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			left: 10%;
			top: 10%;
			width: 590px;
			height: 420px;
			background-color: $primary;
		}
	}

	.message-thumbnail {
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 3rem;
		border: 10px solid $black;
		max-width: 360px;
		width: 100%;
		height: 360px;
	}

	.support,
	.return {
		position: relative;
		background-color: $primary;
		background-position: center center;
		background-size: cover;
		background-repeat: no-repeat;
		overflow: hidden;

		h2 {
			color: $white;

			span {
				color: $black;
			}
		}

		h3 {
			span {
				display: block;
				margin-bottom: 1rem;
				color: $white;
				font-size: $font-size-base * 4;
			}
		}

		&-body {
			padding-left: 8rem;
			padding-right: 8rem;
			padding-top: 8rem;
			padding-bottom: 8rem;
			background-color: $primary;

			@include media-breakpoint-down(lg) {
				padding-left: 4rem;
				padding-right: 4rem;
			}

			@include media-breakpoint-down(sm) {
				padding-left: 2rem;
				padding-right: 2rem;
			}
		}

		.background-heading {
			color: $primary;
			font-size: $font-size-base * 4;
			font-weight: bold;
			line-height: 1.2;
		}
	}

	.support {
		background-image: url(#{$path-img}/home/support-background.jpg);

		&:before {
			content: '';
			position: absolute;
			z-index: 2;
			left: 0;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 0 100vw 220px 0;
			border-color: transparent $white transparent transparent;

			@include media-breakpoint-down(lg) {
				border-width: 0 100vw 110px 0;
			}
		}
	}

	.visual-2 {
		height: 680px;
		background-image: url(#{$path-img}/home/visual-2.jpg);
		background-position: center bottom;
		background-size: cover;
		background-repeat: no-repeat;

		@include media-breakpoint-down(md) {
			height: 340px;
		}

		@include media-breakpoint-down(sm) {
			height: 160px;
		}
	}

	.return {
		background-image: url(#{$path-img}/home/support-background.jpg);

		&:before {
			content: '';
			position: absolute;
			left: 0;
			bottom: 0;
			z-index: 2;
			width: 0;
			height: 0;
			border-style: solid;
			border-width: 220px 0 0 100vw;
			border-color: transparent transparent transparent $white;

			@include media-breakpoint-down(lg) {
				border-width: 110px 0 0 100vw;
			}
		}
	}

	.information {
		margin-bottom: 4rem;

		.information-list__item {
			margin-bottom: 2rem;

			> a {
				text-decoration: none;
			}
		}
	}

	.information-media {
		&__thumbnail {
			margin-right: 2rem;
			width: 270px;
			height: 180px;

			@include media-breakpoint-down(sm) {
				margin-left: auto;
				margin-right: auto;
				margin-bottom: 1rem;
			}
		}

		&__body {
			color: $black;
		}

		&__heading,
		&__date {
			color: $primary;
		}
	}

	.visual-3 {
		background-image: url(#{$path-img}/home/visual-3.jpg);
	}

	.governing-list {
		padding-top: 2rem;
		padding-bottom: 2rem;
		text-align: center;

		&__item {
			margin-bottom: 2rem;
			font-size: $font-size-base * 1.5;
			font-weight: bold;
		}
	}
}
