//
// Thumbnail
//

.thumbnail {
	background-position: top center;
	background-size: cover;
	background-repeat: no-repeat;
	transition: $transition-base;
}

a:hover {
	.thumbnail {
		opacity: .8;
	}
}